<template>
  <div class="elv-report-valuation-header">
    <el-tabs v-model="tabActiveName" type="border-card" class="elv-report-valuation-tab" @tab-click="onChangeTab">
      <el-tab-pane name="entity-valuation-crypto" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('common.crypto') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-valuation-fiat" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('common.fiatFX') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-valuation-future" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('common.future') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-valuation-options" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('valuation.options.options') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-valuation-validator" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('valuation.validatorStaking') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-valuation-inventory" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('common.brokerInventory') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-valuation-assets" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('valuation.assets.navAssets') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-valuation-price" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('valuation.price.navPrice') }}
          </p>
        </template>
      </el-tab-pane>
      <el-tab-pane name="entity-valuation-automation" class="elv-report-detail-pane">
        <template #label>
          <p>
            {{ t('menus.automation') }}
          </p>
        </template>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useEntityStore } from '@/stores/modules/entity'
import { ElMessage } from 'element-plus'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const entityStore = useEntityStore()

const tabActiveName = ref('entity-valuation-crypto')

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onChangeTab = (tab: any) => {
  if (
    tab.props.name === 'entity-valuation-automation' &&
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.automationRule?.view
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  router.push({
    name: tab.props.name
  })
}

onMounted(() => {
  tabActiveName.value = String(route.name)
})
</script>

<style lang="scss" scoped>
.elv-report-valuation-header {
  position: relative;

  .elv-report-valuation-tab {
    margin-top: 16px;
  }
}
</style>
